<div class="card bg-base-200 shadow-xl m-2">
  <div class="card-body">
    <div>
      <img
        src="assets/pricklypear-logo.svg"
        alt="PricklyPear Logo" />
    </div>
    <div class="grid grid-cols-1 gap-5 mt-5 text-center text-xl">
      Your email has been successfully verified! You can now log in to Prickly Pear and access all features.
    </div>
  </div>
</div>
