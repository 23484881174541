import { Component } from '@angular/core';

@Component({
  selector: 'app-account-email-verified',
  templateUrl: './account-email-verified.component.html',
  styleUrl: './account-email-verified.component.scss'
})
export class AccountEmailVerifiedComponent {

}
